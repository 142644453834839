import * as React from "react"
import { graphql } from "gatsby"
import Seo from "../../components/seo"
import Layout from "../../components/layout"
import Person from "./person"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons"

const Company = ({
  data: {
    markdownRemark: { frontmatter },
  },
}) => {
  return (
    <Layout>
      <Seo title="Company" />

      <section className="bg-blue-100">
        <div className="lg:relative py-8 sm:py-24 lg:py-24 container">
          <div className="max-w-md sm:max-w-3xl lg:max-w-6xl lg:grid lg:grid-cols-2">
            <div className="lg:max-w-md">
              <h1 className="mt-4 sm:mt-5 lg:mt-6">
                <span className="text-gray-900 font-light">Our Team</span>
              </h1>
              <hr className="w-12 bg-transparent border-t-2 border-purple-600 mt-4 mb-6" />
              <p className="mt-5">
                With our talented and passionate team, we help organizations
                reach and share important data-driven insights. Our skills in
                data analytics, web development, user interface design, subject
                matter research and analysis. From our main office in the
                Washington DC area and team members across continents, we are
                honored to work with clients from around the world.
              </p>
            </div>
          </div>
          <div className="py-12 sm:relative sm:py-10 lg:absolute lg:inset-y-0 lg:right-0 lg:w-5/12 lg:max-w-2xl">
            <div className="relative sm:max-w-3xl sm:px-0 lg:max-w-none lg:h-full">
              <img
                alt="Company Hero"
                className="lg:max-w-sm md:max-w-sm"
                src="https://storage.googleapis.com/keshif-website/Illustrations/Company.png"
              ></img>
            </div>
          </div>
        </div>
      </section>
      <section className="container py-10 lg:pb-24">
        <h2 className="py-10 sm:py-16 lg:py-8">Team</h2>
        <div className="grid lg:grid-cols-3 md:grid-cols-3 gap-5 pb-8">
          {frontmatter.team
            .filter(i => !i.isAdvisory)
            .map(i => (
              <Person key={i.name} person={i} />
            ))}
          <a
            href="/WorkWithUs"
            className="px-16 disable-underline py-5 mx-auto bg-gray-50 rounded hover:-translate-y-1 hover:shadow-2xl group transform transition-all flex justify-center"
          >
            <div className="grid gap-5 content-center">
              <h2>Join Our Team</h2>
              <p>
                We are always looking for talented and ambitious team members
                with a variety of skill.
              </p>
              <p className="text-purple-600 space-x-2">
                <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
                <span>Share CV</span>
              </p>
            </div>
          </a>
        </div>
        <h2 className="py-10 sm:py-16 lg:py-8">Advisory Group</h2>
        <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-5">
          {frontmatter.team
            .filter(i => i.isAdvisory)
            .map(i => (
              <Person key={i.name} person={i} />
            ))}
        </div>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        team {
          name
          title
          description
          bio
          profile {
            childImageSharp {
              gatsbyImageData(width: 150, quality: 100, placeholder: BLURRED)
            }
          }
          social {
            twitter
            linkedin
            paw
          }
          isAdvisory
        }
      }
    }
  }
`

export default Company
