import React, { useState, Fragment } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Transition, Dialog } from "@headlessui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-light-svg-icons"
import Social from "./social"

export default function Team({ person }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  function closeModal() {
    setIsMenuOpen(false)
  }

  function openModal() {
    setIsMenuOpen(true)
  }

  function bioButton() {
    if (person.bio) {
      return (
        <button
          onClick={() => openModal()}
          type="button"
          className="btn-secondary"
        >
          Bio
        </button>
      )
    }
    return false
  }

  const image = getImage(person.profile)
  return (
    <div
      className="px-5 py-5 bg-gray-50 rounded hover:-translate-y-1 hover:shadow-xl group transform transition-all flex flex-col cursor-default"
    >
      <Transition appear show={isMenuOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-30" />
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-2xl z-50 py-10 px-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow rounded-2xl">
                <div className="flex justify-between items-center">
                  <h3 className="mb-3">{person.name}</h3>
                  <button
                    className="outline-none border-none"
                    type="button"
                    onClick={() => closeModal()}
                  >
                    <FontAwesomeIcon icon={faTimes} size="lg" />
                  </button>
                </div>
                {(person.bio || []).map(function (bioP, _) {
                  return (
                    <p
                      dangerouslySetInnerHTML={{ __html: bioP }}
                      className="mt-2 md:col-span-2 leading-7 font-light"
                    ></p>
                  )
                })}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
      <figure className="space-y-5">
        <div className="w-full flex items-center justify-center">
          <GatsbyImage
            className={`rounded-full border-purple-600 border-3 object-cover z-10 ${
              person.isAdvisory ? "w-40 h-40 lg:w-32 lg:h-32" : "w-40 h-40"
            }`}
            image={image}
            alt={person.name}
          />
        </div>
        <figcaption className="space-y-3">
          <h3 className="group-hover:text-purple-600 transition-colors">
            {person.name}
          </h3>
          <p className="subtitle font-light uppercase">{person.title}</p>
          <p className="pl-2 border-l border-gray-300">{person.description}</p>
        </figcaption>
      </figure>
      <div className="flex justify-between items-end mt-5 flex-1">
        {bioButton()}
        <div className="flex flex-1 justify-end space-x-2">
          {Object.entries(person.social)
            .filter(([key, value]) => value)
            .map(([key, value]) => {
              return (
                <a
                  key={key}
                  className="text-gray-500 hover:text-gray-900  transition duration-150 ease-in-out"
                  target="_blank"
                  rel="noreferrer"
                  aria-label={key}
                  href={value}
                >
                  <Social platform={key} />
                </a>
              )
            })}
        </div>
      </div>
    </div>
  )
}
